<template>
	<div>
		<CustomTable
			:show-filter-status="true"
			:show-filter-source="true"
			:recent-filter="recentFilters"
			:total-pages="totalPages"
			:total-items="totalItems"
			:label-search="getLabelSearch"
			:label-date="$t('FormsFilter.receivedTime')"
			:sources="listSource"
			:statuses="listStatus"
			@search="search"
		>
			<template #headers>
				<tr>
					<th
						v-for="(header, index1) in headerWithRole"
						:key="`${header.title}-${index1}`"
						scope="col"
						:class="`${header.class}`"
					>
						{{ header.title }}
					</th>
				</tr>
			</template>
			<template #body>
				<template v-if="forms && forms.length">
					<tr
						v-for="(form, index2) in forms"
						:key="`${form.id}-${index2}`"
						:class="{ 'highlight-row': form.id === idHighlightRow }"
					>
						<td scope="row" class="table-no text-center fit-id">
							{{ form.displayId }}
						</td>
						<td scope="row" class="text-break text-center see-more">
							{{ form.title }}
						</td>
						<td scope="row" class="text-break text-center" :class="{ fit: isSuperAdmin }">
							{{ form.customerId }}
						</td>
						<td v-if="isSuperAdmin" scope="row" class="text-break text-center email">
							{{ form.email }}
						</td>
						<td scope="row" class="text-break text-center">
							{{ form.received }}
						</td>
						<td scope="row" class="text-break text-center" :class="{ 'fit-id ': isSuperAdmin }">
							{{ capitalize(form.source) }}
						</td>
						<td scope="row" class="text-break text-center" :class="{ fit: isSuperAdmin }">
							{{ getStatus(form) }}
						</td>
						<td scope="row" class="text-break text-center" :class="{ fit: isSuperAdmin }">
							{{ form.isRevision ? form.originalOrderId : form.orderId }}
						</td>
						<td scope="row" class="fit text-center actions">
							<div class="d-flex justify-content-around align-items-center">
								<router-link :to="redirectTo(form)" class="link-icon">
									<span class="prefix-input d-inline-block">
										<font-awesome-icon :icon="['fas', 'pencil-alt']" :title="$t('Action.Edit')" />
									</span>
								</router-link>
								<span
									v-if="isSuperAdmin && checkCancelForm(form)"
									class="prefix-input d-inline-block"
									@click="showConfirmCancel(form)"
								>
									<font-awesome-icon
										:icon="['fas', 'times']"
										class="cancel-icon"
										:title="$t('Action.Cancel')"
									/>
								</span>
							</div>
						</td>
					</tr>
				</template>
				<template v-else>
					<tr class="text-center">
						<td :colspan="headerWithRole.length" class="fit">
							{{ $t("Table.NoData") }}
						</td>
					</tr>
				</template>
			</template>
		</CustomTable>
		<CustomModal
			v-if="isCancelFormModal"
			:title="$t('FormMessages.cancel.header')"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Cancel')"
			@submit-modal="sure"
			@cancel-modal="cancel"
		>
			<template #content>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(sure)">
						<div class="custom">
							<div class="custom__input">
								<div class="form-group position-relative mb-0">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="form_reason"
										:rules="`requiredExport|max:${CANCEL_REASON}`"
									>
										<div class="form-group row">
											<label
												for="reason"
												class="col-12 col-xl-12 col-lg-12 col-md-12 col-form-label"
												>{{ $t("FormsField.reason") }}</label
											>
											<div :class="['col-12 col-xl-12 col-lg-12 col-md-12', classes]">
												<textarea
													id="description"
													v-model.trim="reason"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('FormsField.reason')"
													autocomplete="description"
													:maxlength="CANCEL_REASON"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>
					</form>
				</ValidationObserver>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { GET_FORMS, CANCEL_FORM, CANCEL_FORM_WEB } from "../store/action-types"
import { RESET_FILTERS_FORM_LIST, SET } from "../store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState, mapMutations } = createNamespacedHelpers("forms")
import { STATUS_FORM, SOURCES, USER_ROLE, CANCEL_REASON } from "@/shared/plugins/constants"
import { capitalize } from "lodash-es"
import moment from "moment"

export default {
	name: "FormManagement",
	data() {
		return {
			capitalize,
			CANCEL_REASON,
			formCancel: null,
			reason: null,
			headers: [
				{
					title: this.$t("FormsFieldTable.ID"),
					class: "fit-id text-center",
				},
				{
					title: this.$t("FormsFieldTable.Title"),
					class: "text-nowrap title text-center",
				},
				{
					title: this.$t("FormsFieldTable.CustomerID"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("FormsFieldTable.Email"),
					class: "text-nowrap text-center email",
				},
				{
					title: this.$t("FormsFieldTable.ReceivedTime"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("FormsFieldTable.Source"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("FormsFieldTable.Status"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("FormsFieldTable.Order"),
					class: "text-nowrap text-center",
				},
				{
					title: this.$t("TechnicianManagement.Actions"),
					class: "fit text-center",
				},
			],
			listStatus: [
				{
					name: this.$t("FormsFilterName.Responded"),
					value: STATUS_FORM.RESPONDED,
				},
				{
					name: this.$t("FormsFilterName.Unreviewed"),
					value: STATUS_FORM.UNREVIEW,
				},
				{
					name: this.$t("FormsFilterName.Requested"),
					value: STATUS_FORM.REQUESTED,
				},
				{
					name: this.$t("FormsFilterName.Reviewed"),
					value: STATUS_FORM.REVIEWED,
				},
				{
					name: this.$t("FormsFilterName.Cancelled"),
					value: STATUS_FORM.CANCELLED,
				},
			],
			listSource: [
				{
					name: this.$t("FormsFilterName.Mail"),
					value: SOURCES.MAIL,
				},
				{
					name: this.$t("FormsFilterName.Form"),
					value: SOURCES.FORM,
				},
				{
					name: this.$t("FormsFilterName.Web"),
					value: SOURCES.WEB,
				},
			],
			statusIgnore: [STATUS_FORM.REVIEWED, STATUS_FORM.CANCELLED],
		}
	},
	computed: {
		...mapState([
			"forms",
			"totalItems",
			"totalPages",
			"recentFilters",
			"idHighlightRow",
			"isCancelFormModal",
		]),
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		isSuperAdmin() {
			if (this.currentUser.role !== USER_ROLE.QA) {
				return true
			}
			return false
		},
		headerWithRole() {
			if (this.currentUser && !this.isSuperAdmin) {
				const newHeader = [...this.headers]
				const indexRemove = newHeader.findIndex(el => el.title === this.$t("FormsFieldTable.Email"))
				newHeader.splice(indexRemove, 1)
				return newHeader
			}
			return this.headers
		},
		getLabelSearch() {
			return this.isSuperAdmin
				? this.$t("FormsFilter.searchByAdmin")
				: this.$t("FormsFilter.searchByQA")
		},
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.listSource = this.listSource.filter(el => el.value !== SOURCES.WEB)
		}
	},
	beforeDestroy() {
		if (!this.$route.path.includes("forms")) this.RESET_FILTERS_FORM_LIST()
	},
	methods: {
		...mapActions({
			GET_FORMS,
			CANCEL_FORM,
			CANCEL_FORM_WEB,
		}),
		...mapMutations({ RESET_FILTERS_FORM_LIST, SET }),
		getNo(no) {
			return no + 1 + (+this.recentFilters.skip || 0)
		},
		search: async function (params = {}) {
			await this.GET_FORMS(params)
		},
		showConfirmCancel(form) {
			this.formCancel = form
			this.SET(["isCancelFormModal", true])
			this.$store.commit("set", ["modalShow", true])
		},
		redirectTo(form) {
			if (form.source !== SOURCES.WEB) {
				if (!form.orderId && form.status !== STATUS_FORM.CANCELLED) {
					return {
						name: "UpdateForm",
						params: { id: form.id },
					}
				}
				return {
					name: "FormDetail",
					params: { id: form.id },
				}
			}

			if (!form.orderId && form.status !== STATUS_FORM.CANCELLED) {
				return {
					name: "UpdateFormWeb",
					params: { id: form.id },
				}
			}
			return {
				name: "FormWebDetail",
				params: { id: form.id },
			}
		},
		async sure() {
			const isValid = await this.$refs.form.validate()
			if (!isValid) return

			const params = {
				id: this.formCancel.id,
				reason: this.reason,
			}
			let res = null
			if (this.formCancel.source !== SOURCES.WEB) {
				res = await this.CANCEL_FORM(params)
			} else {
				res = await this.CANCEL_FORM_WEB(params)
			}
			if (res.ok) {
				this.search(this.recentFilters)
				this.SET(["isCancelFormModal", false])
				this.$store.commit("set", ["modalShow", false])
				this.formCancel = null
				this.reason = null
			}
		},
		cancel() {
			this.SET(["isCancelFormModal", false])
			this.reason = null
			this.formCancel = null
		},
		checkCancelForm(form) {
			if (form.source && !form.orderId && !this.statusIgnore.includes(form.status)) {
				return true
			}
			return false
		},
		checkReceivedDate(date) {
			if (!date || !moment(date, "DD-MM-YYYY HH:mm").isValid()) return null
			return moment(date, "DD-MM-YYYY HH:mm").format("DD-MM-YYYY HH:mm")
		},
		getStatus(item) {
			if (item.status === STATUS_FORM.UNREVIEW) {
				return this.$t("FormsFilterName.Unreviewed")
			}
			return capitalize(item.status)
		},
	},
}
</script>
<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
